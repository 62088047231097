import * as React from 'react'

import { Button } from '@toasttab/buffet-pui-buttons'
import { CardContainer } from '@toasttab/buffet-pui-card'
import { useState } from 'react'
import { FraudThresholdsModal } from './components/FraudThresholdsModal'

import { useCompanyCode } from '@toasttab/ec-session'
import { ConfirmationModal } from './components/ConfirmationModal'
import { CustomerThresholdsAuditModal } from './components/CustomerThresholdsAuditModal'
import { CustomerThresholdsModal } from './components/CustomerThresholdsModal'
import { ThresholdsAuditModal } from './components/ThresholdsAuditModal'
import {
  CustomerFraudThresholdsRequest,
  FraudThresholds
} from './models/FraudThresholds'
import {
  useCustomerThresholdMutation,
  useThresholdMutation
} from './queries/FraudQueries'

export function FraudThreshold({
  customerUuid
}: {
  customerUuid: string | null
}) {
  const [isConfirmationModalOpen, setConfirmationModalOpen] =
    useState<boolean>(false)
  const [isThresholdModalOpen, setThresholdModalOpen] = useState<boolean>(false)
  const [isAuditModalOpen, setAuditModalOpen] = useState<boolean>(false)
  const [thresholdRequest, setThresholdRequest] =
    useState<FraudThresholds | null>(null)

  const [customerThresholdRequest, setCustomerThresholdRequest] =
    useState<CustomerFraudThresholdsRequest | null>(null)

  const { mutate: updateCustomerThreshold } = useCustomerThresholdMutation(
    () => {
      setConfirmationModalOpen(false)
      setCustomerThresholdRequest(null)
    }
  )

  const { mutate: updateThreshold } = useThresholdMutation(() => {
    setConfirmationModalOpen(false)
    setThresholdRequest(null)
  })

  const companyCode = useCompanyCode()

  return (
    <CardContainer
      className='border-none py-5 px-4 h-max'
      noElevation
      noPadding
      verticalEdge='rounded'
    >
      <div className='space-y-4'>
        <div className='font-semibold type-large'>Alert Parameters</div>
        <div> View and edit alert settings for the dashboard</div>
        <Button
          variant='secondary'
          className='w-full'
          onClick={() => setThresholdModalOpen(true)}
        >
          View parameters
        </Button>
        <Button
          variant='link'
          className='w-full'
          onClick={() => setAuditModalOpen(true)}
        >
          View past changes
        </Button>
        {customerUuid ? (
          <>
            <CustomerThresholdsModal
              isModalOpen={isThresholdModalOpen}
              customerUuid={customerUuid}
              handleModalClose={(
                request: CustomerFraudThresholdsRequest | null
              ) => {
                setThresholdModalOpen(false)
                setCustomerThresholdRequest(request)
                if (request) {
                  setConfirmationModalOpen(true)
                }
              }}
            />
            <CustomerThresholdsAuditModal
              handleModalClose={() => setAuditModalOpen(false)}
              customerUuid={customerUuid}
              isModalOpen={isAuditModalOpen}
            />
            <ConfirmationModal
              header='Are you sure you want to change alert parameters?'
              saveText='Yes, change parameters'
              handleModalClose={() => {
                setConfirmationModalOpen(false)
                setCustomerThresholdRequest(null)
              }}
              isModalOpen={
                !!customerThresholdRequest && isConfirmationModalOpen
              }
              mutate={() => {
                if (customerThresholdRequest) {
                  updateCustomerThreshold(customerThresholdRequest)
                }
              }}
              body={
                <div>
                  These parameters will only apply to <b>{companyCode}</b>.
                </div>
              }
            />
          </>
        ) : (
          <>
            <FraudThresholdsModal
              isModalOpen={isThresholdModalOpen}
              handleModalClose={(request: FraudThresholds | null) => {
                setThresholdModalOpen(false)
                setThresholdRequest(request)
                if (request) {
                  setConfirmationModalOpen(true)
                }
              }}
            />
            <ThresholdsAuditModal
              handleModalClose={() => setAuditModalOpen(false)}
              isAuditModalOpen={isAuditModalOpen}
            />
            <ConfirmationModal
              header='Are you sure you want to change alert parameters?'
              saveText='Yes, change parameters'
              handleModalClose={() => {
                setConfirmationModalOpen(false)
                setThresholdRequest(null)
              }}
              isModalOpen={!!thresholdRequest && isConfirmationModalOpen}
              mutate={() => {
                if (thresholdRequest) {
                  updateThreshold(thresholdRequest)
                }
              }}
              body={
                <div>
                  These parameters will apply to all company codes unless
                  overridden on a company level.
                </div>
              }
            />
          </>
        )}
      </div>
    </CardContainer>
  )
}
