import {
  getDefaultQuarter,
  getDefaultYear,
  queryClient
} from '@local/api/client'
import { useMutation } from '@tanstack/react-query'
import { SelectField, ToggleSwitchField } from '@toasttab/buffet-pui-forms'
import { SelectOption } from '@toasttab/buffet-pui-select'
import { useSnackBar } from '@toasttab/buffet-pui-snackbars'
import { InfoTooltip } from '@toasttab/buffet-pui-tooltip'
import { Formik } from 'formik'
import * as React from 'react'
import * as Yup from 'yup'
import { CustomersSelectMultiple } from './CustomersSelectMultiple'
import { Q4EnterpriseConfirmation } from './Q4EnterpriseConfirmation'
import { RtsRequestBody } from './models/rtsModel'
import { buildRtsRequestBody } from './shared/functions'

type FormProps = {
  refetch: () => void
}

export const RtsForm = ({ refetch }: FormProps) => {
  const { showSuccessSnackBar, showErrorSnackBar } = useSnackBar()

  const [isConfirmationOpen, setIsConfirmationOpen] = React.useState(false)

  const yearOptions = queryClient.getQueryData(['years']) as SelectOption[]
  const quarterOptions = queryClient.getQueryData([
    'quarters'
  ]) as SelectOption[]

  const { mutateAsync: generate } = useMutation({
    mutationFn: async (requestBody: RtsRequestBody) => {
      const response = await fetch('/rts-generation-job/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/com.toasttab.payroll.v1+json'
        },
        body: JSON.stringify(requestBody)
      })

      if (!response.ok) {
        throw new Error('HTTP error: ' + response.status)
      }

      return response
    },
    onSuccess: () => {
      showSuccessSnackBar('RTS file is being generated')
    },
    onError: (error) => {
      showErrorSnackBar('Error generating RTS file')
      console.error('Error generating RTS file: ', error)
    }
  })

  return (
    <Formik
      initialValues={{
        quarter: getDefaultQuarter(new Date()),
        year: getDefaultYear(new Date()),
        method: 'manuallySelect',
        feinList: [],
        includeMissingSsns: false,
        forPriors: false,
        priorsStartQuarter: undefined
      }}
      validationSchema={Yup.object().shape({
        year: Yup.number().required(),
        quarter: Yup.number().required(),
        method: Yup.string().required(),
        feinList: Yup.array().when('method', {
          is: (value: string) => value === 'manuallySelect',
          then: (schema) =>
            schema
              .required()
              .min(
                1,
                'At least one customer must be selected when manually selecting'
              )
        }),
        priorsStartQuarter: Yup.number().when('method', {
          is: (value: string) => value === 'priors',
          then: (schema) =>
            schema
              .required('Quarter start is required when generating priors')
              .min(
                Yup.ref('quarter'),
                'Quarter start must be greater than or equal to the quarter you are trying to generate'
              )
        })
      })}
      onSubmit={(values, { resetForm }) => {
        if (values.quarter === 4 && values.method === 'enterprise') {
          setIsConfirmationOpen(true)
        } else {
          const body = buildRtsRequestBody(values)

          generate(body)
            .then(() => refetch())
            .then(() => resetForm())
        }
      }}
    >
      {(props) => (
        <form
          onSubmit={(event) => {
            event.preventDefault()
            props.handleSubmit()
          }}
          id='rts-form'
        >
          <div className='grid grid-cols-3 gap-3 mb-4'>
            <SelectField
              label='Year'
              name='year'
              testId='year'
              value={props.values.year}
              options={yearOptions}
            />
            <SelectField
              label='Quarter'
              name='quarter'
              testId='quarter'
              value={props.values.quarter}
              options={quarterOptions}
            />
            <SelectField
              label='Generation Method'
              name='method'
              testId='method'
              value={props.values.method}
              itemToValue={(item) => item.value}
              options={[
                { label: 'Manually Select', value: 'manuallySelect' },
                { label: 'Enterprise', value: 'enterprise' },
                { label: "Onboarding Customers' Priors", value: 'priors' }
              ]}
            />
          </div>
          {props.values.method === 'manuallySelect' && (
            <CustomersSelectMultiple />
          )}
          <div className='mt-4 grid grid-cols-3 gap-3'>
            <div className='col-span-2 flex items-center'>
              <ToggleSwitchField
                name='includeMissingSsns'
                aria-label='include-missing-ssns'
              />
              <span className='ml-4'>Include employees with missing SSNs</span>
              <InfoTooltip className='max-w-xs'>
                This is typically used when pulling RTS files for priors
              </InfoTooltip>
            </div>
            {props.values.method === 'priors' && (
              <SelectField
                label='Quarter Start'
                name='priorsStartQuarter'
                testId='priorsStartQuarter'
                value={props.values.priorsStartQuarter}
                options={quarterOptions}
                className='col-span-1'
              />
            )}
          </div>
          <Q4EnterpriseConfirmation
            isOpen={isConfirmationOpen}
            setIsOpen={setIsConfirmationOpen}
            generate={generate}
            refetch={refetch}
          />
        </form>
      )}
    </Formik>
  )
}
