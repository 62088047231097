import { Button } from '@toasttab/buffet-pui-buttons'
import { Modal, ModalHeader } from '@toasttab/buffet-pui-modal'
import * as React from 'react'

export function ConfirmationModal({
  header,
  saveText,
  isModalOpen,
  handleModalClose,
  mutate,
  body
}: {
  header: string
  saveText: string
  handleModalClose: () => void
  isModalOpen: boolean
  mutate: () => void
  body: React.ReactNode
}) {
  return (
    <>
      <Modal isOpen={isModalOpen}>
        <ModalHeader>{header}</ModalHeader>
        <Modal.Body>
          <div className='space-y-3'>{body}</div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className='flex-grow sm:flex-none'
            variant='link'
            onClick={() => {
              handleModalClose()
            }}
          >
            Cancel
          </Button>
          <Button
            className='flex-grow sm:flex-none'
            onClick={() => {
              mutate()
            }}
          >
            {saveText}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
