import { Button } from '@toasttab/buffet-pui-buttons'
import { Modal, ModalHeader } from '@toasttab/buffet-pui-modal'
import * as React from 'react'
import { PayPeriodRequest } from '../models/pausedPayrollDetails'
import { usePayPeriodsAction } from '../queries/FraudQueries'

export function PausedPayPeriodConfirmationModal({
  request,
  isModalOpen,
  handleModalClose
}: {
  request: PayPeriodRequest | null
  handleModalClose: (reload: boolean) => void
  isModalOpen: boolean
}) {
  const { mutate: closeOrResumePayPeriod } = usePayPeriodsAction(
    request,
    () => handleModalClose(true),
    () => handleModalClose(false)
  )

  const isCloseAction = request?.action === 'close'

  return (
    <>
      <Modal isOpen={!!request && isModalOpen}>
        <ModalHeader>
          {isCloseAction
            ? 'Are you sure you want to manually close this payroll period?'
            : 'Are you sure you want to resume sweep?'}
        </ModalHeader>
        <Modal.Body>
          <div className='space-y-3'>
            <div>
              This payroll period is considered fraud and will not be processed
              and distributed to employees. You cannot undo this action.
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className='flex-grow sm:flex-none'
            variant='link'
            onClick={() => {
              handleModalClose(false)
            }}
          >
            Cancel
          </Button>
          <Button
            className='flex-grow sm:flex-none'
            onClick={() => {
              closeOrResumePayPeriod(request!.action)
            }}
          >
            {isCloseAction ? 'Manually close' : 'Resume sweeps'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
