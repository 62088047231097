import * as React from 'react'
import cx from 'classnames'
import { IconWrapper, IconProps, sizes } from '../../IconWrapper'
export const ArrowDecreaseIcon = React.forwardRef(function ArrowDecreaseIcon(
  props: IconProps,
  ref?: React.ForwardedRef<HTMLElement>
) {
  return (
    <IconWrapper ref={ref} {...props}>
      <>
        {
          <svg
            width={24}
            height={24}
            viewBox='0 0 24 24'
            fill='none'
            className={cx(
              'inline-block align-middle',
              sizes[props.size || 'sm'].className
            )}
          >
            <path
              d='M16.527 7.5H7.57c-1.642 0-2.09 1.2-.895 2.8l4.18 5.6c.447.6.746.6 1.194.6.448 0 .746-.2 1.045-.6l4.18-5.6c1.194-1.6.895-2.8-.747-2.8z'
              fill='currentColor'
            />
          </svg>
        }
      </>
    </IconWrapper>
  )
})
