import { Modal, ModalBody, ModalHeader } from '@toasttab/buffet-pui-modal'
import * as React from 'react'
import { CustomerFraudThresholdsAudit } from '../models/FraudThresholds'

import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
import { useEffect } from 'react'
import { Column } from 'react-table'
import { useCustomerThresholdsAudit } from '../queries/FraudQueries'
import { AuditTable } from './AuditTable'

export function CustomerThresholdsAuditModal({
  isModalOpen,
  handleModalClose,
  customerUuid
}: {
  handleModalClose: () => void
  isModalOpen: boolean
  customerUuid: string
}) {
  const { data, isFetching, refetch } = useCustomerThresholdsAudit(customerUuid)

  const columns: Column<CustomerFraudThresholdsAudit>[] = React.useMemo(
    () => [
      { Header: 'Updated by', accessor: 'createdBy', id: 'createdBy' },
      { Header: 'Date', accessor: 'createdAt', id: 'createdAt' },
      {
        Header: '# Payrolls',
        accessor: 'pastPayrollCount',
        id: 'pastPayrollCount'
      },
      {
        Header: 'Net average',
        accessor: 'netEarningAverage',
        id: 'netEarningAverage'
      },
      {
        Header: 'Gross average',
        accessor: 'grossEarningAverage',
        id: 'grossEarningAverage'
      },
      {
        Header: 'Net variance',
        accessor: 'netEarningGrowthPercent',
        id: 'netEarningGrowthPercent'
      },
      {
        Header: 'Gross variance',
        accessor: 'grossEarningGrowthPercent',
        id: 'grossEarningGrowthPercent'
      },
      {
        Header: 'Deleted',
        accessor: 'deleted',
        id: 'deleted'
      }
    ],
    []
  )

  useEffect(() => {
    if (isModalOpen) {
      refetch()
    }
  }, [isModalOpen])

  return (
    <>
      <Modal
        className='w-max'
        isOpen={isModalOpen}
        size='full'
        onRequestClose={() => {
          handleModalClose()
        }}
      >
        <ModalHeader>
          <div>View past changes</div>
        </ModalHeader>
        <ModalBody>
          {isFetching && <MerryGoRound />}
          {!isFetching && !data?.length && (
            <div className='type-default w-96 py-4'>
              There are no past changes
            </div>
          )}
          {!isFetching && data?.length && (
            <AuditTable<CustomerFraudThresholdsAudit>
              data={data}
              columns={columns}
            />
          )}
        </ModalBody>
      </Modal>
    </>
  )
}
