import * as React from 'react'
import { RemoveFields } from '@toasttab/buffet-shared-types'
import {
  ToggleSwitchWithText,
  ToggleSwitchWithTextProps
} from '@toasttab/buffet-pui-toggle-switches'
import { useFieldFast } from '../useFieldFast'
import {
  FormValuesWithName,
  PrimitiveToggleValue,
  SharedToggleSwitchFieldProps
} from '../commonTypes'

export interface ToggleSwitchWithTextFieldProps<
  FormValues extends FormValuesWithName = string,
  TAffirmative extends PrimitiveToggleValue = PrimitiveToggleValue,
  TNegative extends PrimitiveToggleValue = TAffirmative
> extends SharedToggleSwitchFieldProps<FormValues, TAffirmative, TNegative>,
    RemoveFields<ToggleSwitchWithTextProps, 'name'> {}

export const ToggleSwitchWithTextField = <
  FormValues extends FormValuesWithName = string
>({
  onChange,
  activeValue = true,
  inactiveValue = false,
  ...props
}: ToggleSwitchWithTextFieldProps<FormValues>) => {
  const [{ value }, , helpers] = useFieldFast({ ...props, size: undefined })
  return (
    <ToggleSwitchWithText
      {...props}
      isActive={value === activeValue}
      onChange={(e) => {
        onChange?.(e)
        helpers.setValue(value === activeValue ? inactiveValue : activeValue)
      }}
    />
  )
}
