import { useMutation } from '@tanstack/react-query'
import { Button } from '@toasttab/buffet-pui-buttons'
import { Modal } from '@toasttab/buffet-pui-modal'
import { Select } from '@toasttab/buffet-pui-select'
import { useSnackBar } from '@toasttab/buffet-pui-snackbars'
import { Textarea } from '@toasttab/buffet-pui-text-area'
import * as React from 'react'
import { PostedPayPeriod } from './models/voidCheckModel'

const OTHER_REASON = 'Other (specify)'
export function VoidCheckConfirmationModal({
  selectedPayPeriod,
  setModalOpen,
  isModalOpen,
  selectedChecks
}: {
  selectedPayPeriod: PostedPayPeriod
  setModalOpen: (reload: boolean) => void
  isModalOpen: boolean
  selectedChecks: { employeeUuid: string; payStubNumber: number }[]
}) {
  const { showSuccessSnackBar, showErrorSnackBar } = useSnackBar()
  const [note, setNote] = React.useState('')
  const [reason, setReason] = React.useState<string | null>(null)

  const { mutate: submitVoidRequests } = useMutation({
    mutationFn: async () => {
      const response = await fetch('/void-requests/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/com.toasttab.payroll.v1+json'
        },
        body: JSON.stringify({
          payPeriodUuid: selectedPayPeriod.uuid,
          note: reason && reason !== OTHER_REASON ? reason : note.trim(),
          checks: selectedChecks
        })
      })
      if (!response.ok) {
        throw new Error('HTTP error: ' + response.status)
      }
    },
    onSuccess: () => {
      setModalOpen(true)
      setNote('')
      setReason(null)
      showSuccessSnackBar('Void requests have been submitted')
    },
    onError: () => {
      setModalOpen(false)
      showErrorSnackBar('Error requesting void checks')
    }
  })

  return (
    <>
      <Modal isOpen={isModalOpen}>
        <Modal.Header>Void selected checks</Modal.Header>
        <Modal.Body>
          <div className='space-y-3'>
            <div>
              Voiding this payment will remove it from payroll but will not
              reissue checks, stop or reverse direct deposits, and cannot be
              undone.
            </div>
            <div className='text-default'>
              <div className='font-semibold mb-1 '>Payroll</div>
              <div>{selectedPayPeriod.caption.replaceAll('--', '-')}</div>
            </div>
            {selectedPayPeriod.datePrinted && (
              <div className='text-default'>
                <div className='font-semibold mb-1'>Printed</div>
                <div>
                  {new Date(selectedPayPeriod.datePrinted).toLocaleString()}
                </div>
              </div>
            )}
          </div>
          <div className='mt-5 space-y-4'>
            <div className='sm:max-w-sm '>
              <Select
                label='Reason'
                value={reason}
                placeholder='Select a reason to void checks'
                options={[
                  'Incorrect amount',
                  'Incorrect taxes withheld',
                  'Incorrect paid',
                  'Duplicate payment',
                  OTHER_REASON
                ]}
                onChange={setReason}
              ></Select>
            </div>

            {reason === OTHER_REASON && (
              <Textarea
                name='id-of-input-2'
                value={note}
                maxChars={8000}
                onChange={(e) => {
                  setNote(e.target.value.slice(0, 8000))
                }}
                invalid={note.length > 8000}
                errorText='Input cannot exceed 8000 characters'
              />
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className='flex-grow sm:flex-none'
            variant='link'
            onClick={() => {
              setModalOpen(false)
            }}
          >
            Cancel
          </Button>
          <Button
            className='flex-grow sm:flex-none'
            onClick={() => {
              submitVoidRequests()
            }}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
