import { Button } from '@toasttab/buffet-pui-buttons'
import { Modal } from '@toasttab/buffet-pui-modal'
import { useFormikContext } from 'formik'
import * as React from 'react'
import { RtsRequestBody } from './models/rtsModel'
import { buildRtsRequestBody } from './shared/functions'

type ConfirmationModalProps = {
  isOpen: boolean
  setIsOpen: (open: boolean) => void
  generate: (requestBody: RtsRequestBody) => Promise<Response>
  refetch: () => void
}

export const Q4EnterpriseConfirmation = ({
  isOpen,
  setIsOpen,
  generate,
  refetch
}: ConfirmationModalProps) => {
  const { values, resetForm } = useFormikContext<RtsRequestBody>()

  return (
    <>
      <Modal isOpen={isOpen}>
        <Modal.Body>
          <div className='mt-4 mb-2'>
            <b>Please Note</b>: This action will remove all W2s from all
            customers across all databases. Are you sure you want to proceed?
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className='flex-grow sm:flex-none'
            variant='link'
            onClick={() => {
              setIsOpen(false)
            }}
          >
            Cancel
          </Button>
          <Button
            className='flex-grow sm:flex-none'
            onClick={() => {
              const body = buildRtsRequestBody(values)

              generate(body)
                .then(() => refetch())
                .then(() => resetForm())
              setIsOpen(false)
            }}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
