import * as React from 'react'
import cx from 'classnames'
import {
  SelectButton,
  SelectButtonContents,
  SelectButtonProps
} from '../SelectButton'
import { t, loadStrings, Trans } from '../defaultStrings'

export interface SelectButtonMultipleProps<TItem>
  extends Omit<
    SelectButtonProps,
    'selected' | 'customHeight' | 'align' | 'children'
  > {
  /**
   * The selected items to render as children of the button
   */
  selectedItems?: Array<TItem>
  /**
   * a function used to determine what the text value of an object is
   */
  itemToString?: (arg: TItem | null) => string
  /** maximum number of chips to display before switching to show the number of chips and the `maxChipsPlaceholder` */
  maxChips?: number
  /** The text to append to the number of selected chips when selected chips meets or exceeds `maxChip` */
  maxChipsPlaceholder?: string
  /** enables wrapping of chips to two or more lines */
  enableMultiLineChips?: boolean
  /** */
  variant?: 'chips' | 'comma-separated'
}

export const SelectButtonMultiple = React.forwardRef<
  HTMLButtonElement,
  SelectButtonProps
>(function SelectButtonMultiple<TItem extends any>(
  {
    selectedItems = [],
    itemToString = (item) => String(item),
    size = 'auto',
    variant = 'chips',
    enableMultiLineChips,
    maxChips = 3,
    maxChipsPlaceholder,
    placeholder,
    testId,
    transparentBackground,
    ...props
  }: SelectButtonMultipleProps<TItem>,
  ref?: React.ForwardedRef<HTMLButtonElement>
) {
  loadStrings()
  const translatedPlaceholder = placeholder || t('placeholder.select-one')
  const chipsRef = React.useRef<HTMLDivElement>(null)
  const [areChipsOverflowing, setAreChipsOverflowing] = React.useState(false)

  React.useEffect(() => {
    // useEffect rather than useMemo because we can only check clientWidth and scrollWidth
    // _after_ the render caused by a change in the number of selected items
    const isOverflowing =
      variant === 'chips' &&
      !enableMultiLineChips &&
      selectedItems.length > 1 && // we will truncate a chip if it's the only one
      chipsRef.current &&
      chipsRef.current.clientWidth < chipsRef.current.scrollWidth
    setAreChipsOverflowing(isOverflowing || false)
  }, [enableMultiLineChips, selectedItems.length, variant])

  const MaxChipsPlaceholder = () => (
    <SelectButtonContents size={size}>
      {maxChipsPlaceholder ? (
        <Trans
          i18nKey='placeholder.custom-max-chips'
          values={{
            count: selectedItems.length,
            selected: maxChipsPlaceholder
          }}
          components={[<span className='font-semibold' key='0' />]}
        />
      ) : (
        <Trans
          i18nKey='placeholder.max-chips'
          values={{ count: selectedItems.length }}
          components={[<span className='font-semibold' key='0' />]}
        />
      )}
    </SelectButtonContents>
  )

  return (
    <SelectButton
      {...props}
      ref={ref}
      testId={testId}
      size={size}
      align='top'
      selected={selectedItems.length > 0}
      customHeight={enableMultiLineChips}
      transparentBackground={transparentBackground}
    >
      {variant === 'comma-separated' ? (
        <SelectButtonContents size={size}>
          {selectedItems.map((item) => itemToString(item)).join(', ') ||
            translatedPlaceholder}
        </SelectButtonContents>
      ) : (
        <>
          {selectedItems.length === 0 ? (
            <SelectButtonContents size={size}>
              {translatedPlaceholder}
            </SelectButtonContents>
          ) : selectedItems.length > maxChips ? (
            <MaxChipsPlaceholder />
          ) : (
            <div
              ref={chipsRef}
              className='flex flex-col flex-grow overflow-hidden'
            >
              {areChipsOverflowing && <MaxChipsPlaceholder />}
              <div
                className={cx('flex', {
                  'flex-wrap': enableMultiLineChips,
                  'pt-2': !areChipsOverflowing,
                  'h-0 invisible': areChipsOverflowing
                })}
              >
                {selectedItems.map((item, index) => (
                  <div
                    className={cx(
                      'px-3 mb-2 mr-2 rounded whitespace-nowrap bg-darken-8',
                      {
                        truncate:
                          enableMultiLineChips || selectedItems.length === 1,
                        'py-1 md:py-0': size === 'auto',
                        'py-1': size === 'base' || size === 'lg',
                        'py-0': size === 'sm'
                      }
                    )}
                    key={`${testId}-chip-${index}`}
                  >
                    {itemToString(item)}
                  </div>
                ))}
              </div>
            </div>
          )}
        </>
      )}
    </SelectButton>
  )
}) as React.NamedExoticComponent &
  (<T>(
    props: SelectButtonMultipleProps<T> & React.RefAttributes<HTMLButtonElement>
  ) => React.ReactElement | null)
