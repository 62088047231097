import {
  PausedPayPeriod,
  PayPeriodRequest
} from '../models/pausedPayrollDetails'

import { useMutation, useQuery } from '@tanstack/react-query'
import { useSnackBar } from '@toasttab/buffet-pui-snackbars'
import {
  CustomerFraudThresholds,
  CustomerFraudThresholdsAudit,
  CustomerFraudThresholdsRequest,
  FraudThresholds,
  FraudThresholdsAudit
} from '../models/FraudThresholds'

export const FRAUD_DASHBOARD_PATH = '/fraud'

const fetchPausedPayPeriods = async (customerUuid: string | null) => {
  const filter = customerUuid ? `?customerUuid=${customerUuid}` : ''
  const res = await fetch(`${FRAUD_DASHBOARD_PATH}/payrolls${filter}`)
  if (!res.ok) {
    throw new Error('HTTP error: ' + res.status)
  }
  return res.json()
}

const fetchThreshold = async () => {
  const res = await fetch(`${FRAUD_DASHBOARD_PATH}/thresholds`)
  if (!res.ok) {
    throw new Error('HTTP error: ' + res.status)
  }
  return res
    .json()
    .then((data) => data)
    .catch((_) => null)
}

const fetchThresholdAudit = async () => {
  const res = await fetch(`${FRAUD_DASHBOARD_PATH}/thresholds/audit`)
  if (!res.ok) {
    throw new Error('HTTP error: ' + res.status)
  }
  return res
    .json()
    .then((data) => data)
    .catch((_) => null)
}

const fetchCustomerThreshold = async (customerUuid: string) => {
  const res = await fetch(
    `${FRAUD_DASHBOARD_PATH}/${customerUuid}/customer-thresholds`
  )
  if (!res.ok) {
    throw new Error('HTTP error: ' + res.status)
  }
  return res
    .json()
    .then((data) => data)
    .catch((_) => null)
}

const fetchCustomerThresholdAudit = async (customerUuid: string) => {
  const res = await fetch(
    `${FRAUD_DASHBOARD_PATH}/${customerUuid}/customer-thresholds/audit`
  )
  if (!res.ok) {
    throw new Error('HTTP error: ' + res.status)
  }
  return res
    .json()
    .then((data) => data)
    .catch((_) => null)
}

export const usePausedPayPeriods = (
  customerUuid: string | null
): {
  data: undefined | null | PausedPayPeriod[]
  isFetching: boolean
  refetch: () => void
} => {
  return useQuery({
    queryKey: ['pausedPayPeriods', customerUuid],
    queryFn: () => fetchPausedPayPeriods(customerUuid)
  })
}

export const useThresholds = (): {
  data: undefined | null | FraudThresholds
  isFetching: boolean
  refetch: () => void
} => {
  return useQuery({
    queryKey: ['thresholds'],
    queryFn: fetchThreshold,
    enabled: false
  })
}

export const useThresholdsAudit = (): {
  data: undefined | null | FraudThresholdsAudit[]
  isFetching: boolean
  refetch: () => void
} => {
  return useQuery({
    queryKey: ['thresholds-audit'],
    queryFn: fetchThresholdAudit,
    enabled: true
  })
}

export const useCustomerThresholds = (
  customerUuid: string
): {
  data: undefined | null | CustomerFraudThresholds
  isFetching: boolean
  refetch: () => void
} => {
  return useQuery({
    queryKey: ['customer-thresholds', customerUuid],
    queryFn: () => fetchCustomerThreshold(customerUuid),
    enabled: false
  })
}

export const useCustomerThresholdsAudit = (
  customerUuid: string
): {
  data: undefined | null | CustomerFraudThresholdsAudit[]
  isFetching: boolean
  refetch: () => void
} => {
  return useQuery({
    queryKey: ['customer-thresholds-audit', customerUuid],
    queryFn: () => fetchCustomerThresholdAudit(customerUuid),
    enabled: true
  })
}

export const usePayPeriodsAction = (
  request: PayPeriodRequest | null,
  successAction: () => void,
  failAction: () => void
) => {
  const { showSuccessSnackBar, showErrorSnackBar } = useSnackBar()
  return useMutation({
    mutationFn: async (action: 'close' | 'resume') => {
      const response = await fetch(
        `${FRAUD_DASHBOARD_PATH}/payrolls/${action}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/com.toasttab.payroll.v1+json'
          },
          body: JSON.stringify({
            customerUuid: request?.payPeriod.customerUuid,
            payPeriodUuid: request?.payPeriod.uuid
          })
        }
      )
      if (!response.ok) {
        throw new Error('HTTP error: ' + response.status)
      }
    },
    onSuccess: () => {
      successAction()
      showSuccessSnackBar(
        request?.action === 'close'
          ? 'You closed this payroll.'
          : 'You resumed this payroll.'
      )
    },
    onError: () => {
      failAction()
      showErrorSnackBar(
        request?.action === 'close'
          ? 'Error closing pay period.'
          : 'Error resuming pay period.'
      )
    }
  })
}

export const useThresholdMutation = (action: () => void) => {
  const { showSuccessSnackBar, showErrorSnackBar } = useSnackBar()
  return useMutation({
    mutationFn: async (request: FraudThresholds) => {
      const response = await fetch(`${FRAUD_DASHBOARD_PATH}/thresholds`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/com.toasttab.payroll.v1+json'
        },
        body: JSON.stringify(request)
      })
      if (!response.ok) {
        throw new Error('HTTP error: ' + response.status)
      }
    },
    onSuccess: () => {
      action()
      showSuccessSnackBar('You changed alert parameters')
    },
    onError: () => {
      action()
      showErrorSnackBar('Error updating alert parameters.')
    }
  })
}

export const useCustomerThresholdMutation = (action: () => void) => {
  const { showSuccessSnackBar, showErrorSnackBar } = useSnackBar()
  return useMutation({
    mutationFn: async (request: CustomerFraudThresholdsRequest) => {
      const response = await fetch(
        `${FRAUD_DASHBOARD_PATH}/customer-thresholds`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/com.toasttab.payroll.v1+json'
          },
          body: JSON.stringify(request)
        }
      )
      if (!response.ok) {
        throw new Error('HTTP error: ' + response.status)
      }
    },
    onSuccess: () => {
      action()
      showSuccessSnackBar('You changed alert parameters')
    },
    onError: () => {
      action()
      showErrorSnackBar('Error updating alert parameters.')
    }
  })
}

export const useThresholdModeMutation = () => {
  return useMutation({
    mutationFn: async (enableRuleBased: boolean) => {
      const response = await fetch(
        `${FRAUD_DASHBOARD_PATH}/rule-based-check/${enableRuleBased}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/com.toasttab.payroll.v1+json'
          }
        }
      )
      if (!response.ok) {
        throw new Error('HTTP error: ' + response.status)
      }
    }
  })
}

export const useCustomerThresholdDeletion = (action: () => void) => {
  const { showSuccessSnackBar, showErrorSnackBar } = useSnackBar()
  return useMutation({
    mutationFn: async () => {
      const response = await fetch(
        `${FRAUD_DASHBOARD_PATH}/customer-thresholds`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/com.toasttab.payroll.v1+json'
          }
        }
      )
      if (!response.ok) {
        throw new Error('HTTP error: ' + response.status)
      }
    },
    onSuccess: () => {
      action()
      showSuccessSnackBar('You deleted alert parameters')
    },
    onError: () => {
      showErrorSnackBar('Error deleting alert parameters.')
    }
  })
}
