import {
  LayoutProvider,
  Page,
  PageBody,
  Title
} from '@toasttab/buffet-pui-config-templates'

import { Button } from '@toasttab/buffet-pui-buttons'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
import { useCompanyCode, useUser } from '@toasttab/ec-session'
import * as React from 'react'
import { useEffect, useState } from 'react'
import {
  Outlet,
  RouteObject,
  useLocation,
  useSearchParams
} from 'react-router-dom'
import { FraudThreshold } from './FraudThresholds'
import { PausedPayPeriodCard } from './components/PausedPayPeriodCard'
import { PausedPayPeriodConfirmationModal } from './components/PausedPayPeriodConfirmationModal'
import { PayPeriodRequest } from './models/pausedPayrollDetails'
import { usePausedPayPeriods } from './queries/FraudQueries'

export const FraudDashboardRoute: RouteObject = {
  path: 'payroll-ops/fraud-dashboard',
  element: <FraudDashboard />,
  children: []
}

function FraudDashboard() {
  const { isUserPayrollOps, customerUuid } = useUser()
  const companyCode = useCompanyCode()

  const [searchParams] = useSearchParams()

  const isCustomer = searchParams.has('isCustomer')
    ? searchParams.get('isCustomer') === 'true'
    : false

  if (!isUserPayrollOps) {
    return (
      <div className='type-default'>hmm... you aren't suppose to be here</div>
    )
  }
  return (
    <LayoutProvider disableMaxWidth>
      <Page className='mt-10'>
        <PageBody className='type-default'>
          <div className='border-b mb-6 pb-5 flex items-center'>
            <Title className='flex-grow'>
              {isCustomer ? companyCode : 'Enterprise'} payroll fraud
            </Title>
            <Button
              as='a'
              variant='secondary'
              className='w-full lg:w-auto'
              href={`/payroll-ops/fraud-dashboard?isCustomer=${!isCustomer}`}
            >
              Go to {isCustomer ? 'Enterprise' : companyCode} fraud dashboard
            </Button>
          </div>
          <div className='flex md:flex-row space-x-4'>
            <div className='flex-grow'>
              <PayPeriodsList customerUuid={isCustomer ? customerUuid : null} />
            </div>
            <div className='max-w-72'>
              <FraudThreshold customerUuid={isCustomer ? customerUuid : null} />
            </div>
          </div>
          <Outlet />
        </PageBody>
      </Page>
    </LayoutProvider>
  )
}

function PayPeriodsList({ customerUuid }: { customerUuid: string | null }) {
  const location = useLocation()
  const mostRecentScrollToHash = React.useRef<string>('')
  const [isConfirmationModalOpen, setConfirmationModalOpen] =
    useState<boolean>(false)
  const [request, setRequest] = useState<PayPeriodRequest | null>(null)

  const {
    data: pausedPayPeriods,
    isFetching: isFetchingPayPeriods,
    refetch: refetchPayPeriods
  } = usePausedPayPeriods(customerUuid)

  useEffect(() => {
    if (location.hash) {
      let hash = location.hash.replace('#', '')
      const element = document.getElementById(hash)
      if (element && hash !== mostRecentScrollToHash.current) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        })
        mostRecentScrollToHash.current = hash
      }
    }
  })

  if (isFetchingPayPeriods) {
    return <MerryGoRound />
  }

  return pausedPayPeriods?.length ? (
    <div className='space-y-5 flex-grow max-w-4xl'>
      {pausedPayPeriods
        .sort((a, b) => {
          return (
            new Date(b.postedDateTime).getTime() -
            new Date(a.postedDateTime).getTime()
          )
        })
        .map((payPeriod) => (
          <PausedPayPeriodCard
            key={payPeriod.uuid}
            payPeriod={payPeriod}
            setRequest={(request: PayPeriodRequest) => {
              setRequest(request)
              setConfirmationModalOpen(true)
            }}
          />
        ))}
      <PausedPayPeriodConfirmationModal
        isModalOpen={isConfirmationModalOpen}
        request={request}
        handleModalClose={(reload: boolean) => {
          setConfirmationModalOpen(false)
          setRequest(null)
          if (reload) {
            refetchPayPeriods()
          }
        }}
      />
    </div>
  ) : (
    <div className='space-y-5 flex-grow max-w-4xl'>
      There are currently no payrolls on pause.
    </div>
  )
}
