import * as React from 'react'
import {
  LabelledToggleSwitch,
  LabelledToggleSwitchProps
} from '../LabelledToggleSwitch'
import cx from 'classnames'
import {
  TooltipTextLayout,
  TooltipTextLayoutProps
} from '@toasttab/buffet-pui-tooltip'
import { InfoIcon } from '@toasttab/buffet-pui-icons'
import { ScreenSize, useScreenSize } from '@toasttab/use-screen-size'
import { useUniqueId } from '@toasttab/buffet-utils'

export type ToggleSwitchWithTextProps = Omit<
  LabelledToggleSwitchProps,
  'aria-label' | 'aria-labelledby'
> & {
  /** The title of the switch */
  title?: React.ReactNode
  /** Additional details */
  details?: React.ReactNode
  /** The icon to display as helper next to the title */
  helperIconButton?: TooltipTextLayoutProps['helperIconButton']
}

export const ToggleSwitchWithText = ({
  title,
  details,
  testId,
  helperIconButton,
  disabled = false,
  id,
  ...props
}: ToggleSwitchWithTextProps) => {
  const screenSize = useScreenSize()
  const isMobileLayout = screenSize < ScreenSize.MD
  const uniqueId = useUniqueId(id, 'toggle-switch-with-text-')

  const titleDiv = (
    <div
      className={cx(
        'font-semibold type-default text-default items-center flex',
        disabled && 'text-disabled'
      )}
      // Match the height of the LabelledToggleSwitch
      style={{ minHeight: isMobileLayout ? '2rem' : undefined }}
      id={`${uniqueId}-title`}
    >
      {title}
    </div>
  )
  return (
    <div
      data-testid={`${testId}-container`}
      className='flex w-full flex-col items-start gap-2'
    >
      <div className='flex flex-row w-full items-start gap-4'>
        <div className='flex flex-col flex-grow gap-2'>
          <div className='flex justify-between'>
            {helperIconButton ? (
              <TooltipTextLayout
                helperIconButton={helperIconButton}
                disabled={disabled}
                defaultIcon={<InfoIcon size='xs' accessibility='decorative' />}
              >
                {titleDiv}
              </TooltipTextLayout>
            ) : (
              titleDiv
            )}
          </div>
          {details && !isMobileLayout && (
            <div
              className={cx(
                'font-normal type-default',
                disabled ? 'text-disabled' : 'text-secondary'
              )}
              id={`${uniqueId}-details`}
            >
              {details}
            </div>
          )}
        </div>
        <LabelledToggleSwitch
          testId={testId}
          disabled={disabled}
          aria-labelledby={`${uniqueId}-title`}
          aria-describedby={details ? `${uniqueId}-details` : undefined}
          {...props}
        />
      </div>
      {isMobileLayout && details && (
        <div
          className={cx(
            'font-normal type-default',
            disabled ? 'text-disabled' : 'text-secondary'
          )}
          id={`${uniqueId}-details`}
        >
          {details}
        </div>
      )}
    </div>
  )
}
