import { useQuery } from '@tanstack/react-query'
import { Button } from '@toasttab/buffet-pui-buttons'
import { Checkbox } from '@toasttab/buffet-pui-checkbox'
import {
  LayoutProvider,
  Page,
  PageActions,
  PageBody,
  PageHeader,
  Title
} from '@toasttab/buffet-pui-config-templates'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
import { Select, SelectOption } from '@toasttab/buffet-pui-select'
import {
  Body,
  Cell,
  Head,
  HeadingCell,
  Row,
  Table
} from '@toasttab/buffet-pui-table'
import cx from 'classnames'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { Outlet, RouteObject } from 'react-router-dom'
import { CellProps, Column, useRowSelect, useTable } from 'react-table'
import { VoidCheckConfirmationModal } from './VoidCheckConfirmationModal'
import { EmployeeCheck, PostedPayPeriod } from './models/voidCheckModel'

export const VoidChecksRoute: RouteObject = {
  path: 'payroll-ops/void-checks',
  element: <VoidChecks />,
  children: []
}

const EMPTY_LIST: readonly EmployeeCheck[] = []
function VoidChecks() {
  const [selectedPayPeriod, setSelectedPayPeriod] =
    React.useState<SelectOption | null>(null)
  const [isConfirmationModalOpen, setConfirmationModalOpen] =
    useState<boolean>(false)
  const [selectedChecks, setSelectedChecks] = useState<boolean>(false)
  const fetchPostedPayPeriods = async () => {
    const res = await fetch(`/void-requests/posted-pay-periods`)
    if (!res.ok) {
      throw new Error('HTTP error: ' + res.status)
    }
    return res.json()
  }

  const {
    data,
    isFetching
  }: {
    data: undefined | null | PostedPayPeriod[]
    isFetching: boolean
  } = useQuery({
    queryKey: ['postedPayPeriods'],
    queryFn: fetchPostedPayPeriods
  })

  if (isFetching) {
    return <MerryGoRound />
  }

  let payPeriod = data?.find(
    (payPeriod) => payPeriod.uuid === selectedPayPeriod?.value
  )
  return (
    <LayoutProvider>
      <Page>
        <PageHeader>
          <Title>Void Checks Within Payroll</Title>
          <PageActions>
            <Button
              className='w-full lg:w-auto'
              onClick={() => setConfirmationModalOpen(true)}
              disabled={!selectedPayPeriod || !selectedChecks}
            >
              Request voids
            </Button>
          </PageActions>
        </PageHeader>
        <PageBody className='type-default'>
          <div className='type-default text-secondary py-4'>
            {data?.length === 0 && <div>There are no posted pay periods</div>}
            {data && data.length > 0 && (
              <Select
                label='Posted Cycles Year to Date'
                name='payperiod'
                value={selectedPayPeriod}
                options={data.map((d) => ({
                  label:
                    d.caption +
                    (d.datePrinted
                      ? `- Printed Checks: ${new Date(
                          d.datePrinted
                        ).toLocaleString()}`
                      : ''),
                  value: d.uuid
                }))}
                itemToValue={(item) => item}
                onChange={(value) => {
                  setSelectedPayPeriod(value)
                }}
              />
            )}
          </div>
          {selectedPayPeriod?.value && payPeriod && (
            <CheckTable
              selectedPayPeriod={payPeriod}
              isConfirmationModalOpen={isConfirmationModalOpen}
              setConfirmationModalOpen={setConfirmationModalOpen}
              setSelectedChecks={setSelectedChecks}
            />
          )}
          <Outlet />
        </PageBody>
      </Page>
    </LayoutProvider>
  )
}

const TableRowCheckbox = React.forwardRef<HTMLElement, any>(
  ({ indeterminate, density = 'default', ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      // @ts-ignore
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <div
        className={cx({
          'mt-1': density === 'default' || density === 'condensed-x'
        })}
      >
        <Checkbox ref={resolvedRef} {...rest} />
      </div>
    )
  }
)

function CheckTable({
  selectedPayPeriod,
  isConfirmationModalOpen,
  setConfirmationModalOpen,
  setSelectedChecks
}: {
  selectedPayPeriod: PostedPayPeriod
  isConfirmationModalOpen: boolean
  setConfirmationModalOpen: (value: boolean) => void
  setSelectedChecks: (value: boolean) => void
}) {
  const fetchChecks = async () => {
    const res = await fetch(`/void-requests/${selectedPayPeriod.uuid}/checks`)
    if (!res.ok) {
      throw new Error('HTTP error: ' + res.status)
    }
    return res.json()
  }

  const {
    data,
    isFetching,
    refetch
  }: {
    data: undefined | null | EmployeeCheck[]
    isFetching: boolean
    refetch: any
  } = useQuery({
    queryKey: [selectedPayPeriod.uuid],
    queryFn: fetchChecks
  })

  const finishSubmission = (reload: boolean) => {
    if (reload) {
      refetch()
    }
    setConfirmationModalOpen(false)
  }

  const columns: Column<EmployeeCheck>[] = React.useMemo(
    () => [
      {
        Header: 'Employee',
        accessor: 'lastName',
        id: 'employee'
      },
      {
        Header: 'Employee Number',
        accessor: 'employeeNumber',
        id: 'employeeNumber'
      },
      { Header: 'Check Number', accessor: 'checkNumber', id: 'checkNumber' },
      { Header: 'Gross', accessor: 'gross', id: 'gross' },
      { Header: 'Net Earnings', accessor: 'netEarnings', id: 'netEarnings' }
    ],
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows
  } = useTable(
    {
      columns,
      data: data ? data : EMPTY_LIST,
      getRowId: (row: EmployeeCheck) =>
        `${row.uuid.toString()}-${row.payStubNumber}`
    },
    useRowSelect,
    (hooks) => {
      // You can push a new column into the start of each row to handle selection
      hooks.visibleColumns.push(() => [
        {
          id: 'selection',
          className: 'pt-0 pb-0',
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <TableRowCheckbox
              {...getToggleAllRowsSelectedProps()}
              density='default'
            />
          ),
          Cell: ({ row }: CellProps<EmployeeCheck>) => (
            <TableRowCheckbox
              {...row.getToggleRowSelectedProps()}
              density='default'
            />
          )
        },
        ...columns
      ])
    }
  )

  useEffect(() => {
    setSelectedChecks(selectedFlatRows.length > 0)
  }, [selectedFlatRows])

  if (isFetching) {
    return <MerryGoRound />
  }

  if (!data?.length) {
    return <div className='type-default'>There are no checks</div>
  }

  return (
    <>
      <Table {...getTableProps()}>
        <Head>
          {headerGroups.map((headerGroup) => (
            <Row
              {...headerGroup.getHeaderGroupProps()}
              key={`headingrow-${headerGroup.id}`}
            >
              {headerGroup.headers.map((column) => (
                <HeadingCell
                  {...column.getHeaderProps({
                    className: column.className
                  })}
                  key={`row-${headerGroup.id}-col-${column.id}`}
                >
                  {column.render('Header')}
                </HeadingCell>
              ))}
            </Row>
          ))}
        </Head>
        <Body {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row)
            return (
              <Row {...row.getRowProps()} key={`row-${row.id}`}>
                {row.cells.map((cell) => {
                  return (
                    <Cell
                      {...cell.getCellProps({
                        className: cell.column.className
                      })}
                      key={`row-${row.id}-cell-${cell.column.id}`}
                    >
                      {cell.column.id === 'employee'
                        ? `${row.original.firstName} ${row.original.lastName}`
                        : cell.render('Cell')}
                    </Cell>
                  )
                })}
              </Row>
            )
          })}
        </Body>
      </Table>
      <VoidCheckConfirmationModal
        selectedPayPeriod={selectedPayPeriod}
        setModalOpen={finishSubmission}
        isModalOpen={isConfirmationModalOpen}
        selectedChecks={selectedFlatRows.map((row) => ({
          employeeUuid: row.original.uuid,
          payStubNumber: row.original.payStubNumber,
          payPeriodUuid: row.original.payPeriodUuid
        }))}
      />
    </>
  )
}

export default VoidChecks
