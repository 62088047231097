import { IconButton } from '@toasttab/buffet-pui-buttons'
import { AutorenewIcon, DownloadIcon } from '@toasttab/buffet-pui-icons'
import { useSnackBar } from '@toasttab/buffet-pui-snackbars'
import {
  Body,
  Cell,
  Head,
  HeadingCell,
  Row,
  Table
} from '@toasttab/buffet-pui-table'
import * as React from 'react'
import { RtsFile } from './models/rtsModel'
import { getRunType, getStatus } from './shared/functions'

type ListProps = {
  rtsFiles: RtsFile[]
  refetch: () => void
}

export const RtsList = ({ rtsFiles, refetch }: ListProps) => {
  const [loadingRecord, setLoadingRecord] = React.useState<string | null>()
  const { showSuccessSnackBar, showErrorSnackBar } = useSnackBar()

  const download = async (s3FileName: string) => {
    setLoadingRecord(s3FileName)

    const response = await fetch(`/rts-generation-job/download`, {
      method: 'POST',
      body: s3FileName
    })

    if (!response.ok) {
      showErrorSnackBar('Error downloading RTS file')
      setLoadingRecord(null)
      throw new Error('HTTP error: ' + response.status)
    }

    if (!response.body) {
      throw new Error('Response body is null')
    }

    const reader = response.body.getReader()
    const chunks: Uint8Array[] = []

    const read = async () => {
      if (!reader) return

      const { done, value } = await reader.read()

      if (done) {
        showSuccessSnackBar('RTS file successfully downloaded')
        setLoadingRecord(null)

        const blob = new Blob(chunks, { type: 'application/octet-stream' })
        const url = URL.createObjectURL(blob)
        const a = Object.assign(document.createElement('a'), {
          href: url,
          download: s3FileName.split('/').pop()
        })
        a.click()
        URL.revokeObjectURL(url)

        return
      }

      chunks.push(value)
      read()
    }
    read()
  }

  return (
    <Table density='condensed-x'>
      <Head>
        <Row>
          <HeadingCell>Status</HeadingCell>
          <HeadingCell>Quarter</HeadingCell>
          <HeadingCell>Year</HeadingCell>
          <HeadingCell>Run Type</HeadingCell>
          <HeadingCell>Username</HeadingCell>
          <HeadingCell>Started At</HeadingCell>
          <HeadingCell>Completed At</HeadingCell>
          <HeadingCell>
            <IconButton
              icon={<AutorenewIcon aria-label='refresh' />}
              onClick={() => refetch()}
            />
          </HeadingCell>
        </Row>
      </Head>
      <Body>
        {rtsFiles.map((rtsFile: RtsFile) => {
          return (
            <Row key={rtsFile.jobId}>
              <Cell>{getStatus(rtsFile.status)}</Cell>
              <Cell>{rtsFile.quarter}</Cell>
              <Cell>{rtsFile.year}</Cell>
              <Cell>{getRunType(rtsFile.runType)}</Cell>
              <Cell>{rtsFile.username}</Cell>
              <Cell>{rtsFile.startedAt}</Cell>
              <Cell>{rtsFile.completedAt}</Cell>
              <Cell>
                {rtsFile.s3FileName && (
                  <IconButton
                    icon={
                      loadingRecord === rtsFile.s3FileName ? (
                        <AutorenewIcon
                          className='animate-spin'
                          accessibility='decorative'
                        />
                      ) : (
                        <DownloadIcon aria-label='download' />
                      )
                    }
                    onClick={() => download(rtsFile.s3FileName)}
                    disabled={loadingRecord === rtsFile.s3FileName}
                  />
                )}
              </Cell>
            </Row>
          )
        })}
      </Body>
    </Table>
  )
}
