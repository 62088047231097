import { Modal, ModalBody, ModalHeader } from '@toasttab/buffet-pui-modal'
import * as React from 'react'
import { FraudThresholdsAudit } from '../models/FraudThresholds'

import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
import { useEffect } from 'react'
import { Column } from 'react-table'
import { useThresholdsAudit } from '../queries/FraudQueries'
import { AuditTable } from './AuditTable'

export function ThresholdsAuditModal({
  isAuditModalOpen,
  handleModalClose
}: {
  handleModalClose: () => void
  isAuditModalOpen: boolean
}) {
  const {
    data: audit,
    isFetching: isFetchingAudit,
    refetch: refetchAudit
  } = useThresholdsAudit()

  const columns: Column<FraudThresholdsAudit>[] = React.useMemo(
    () => [
      { Header: 'Updated by', accessor: 'createdBy', id: 'createdBy' },
      { Header: 'Date', accessor: 'createdAt', id: 'createdAt' },
      {
        Header: '# Payrolls',
        accessor: 'newCustomerPayrollCount',
        id: 'newCustomerPayrollCount'
      },
      {
        Header: '# Days',
        accessor: 'newCustomerInactiveDay',
        id: 'newCustomerInactiveDay'
      },
      {
        Header: 'Average net',
        accessor: 'newCustomerNetEarningAverage',
        id: 'netEarningAverage'
      },
      {
        Header: 'Average gross',
        accessor: 'newCustomerGrossEarningAverage',
        id: 'grossEarningAverage'
      },
      {
        Header: 'Net limit',
        accessor: 'newEmployeeNetEarningLimit',
        id: 'netEarningLimit'
      },
      {
        Header: 'Gross limit',
        accessor: 'newEmployeeGrossEarningLimit',
        id: 'grossEarningLimit'
      },
      {
        Header: 'Net minimum',
        accessor: 'netEarningMinimum',
        id: 'netEarningMinimum'
      },
      {
        Header: 'Gross minimum',
        accessor: 'grossEarningMinimum',
        id: 'grossEarningMinimum'
      },
      {
        Header: 'Net variance',
        accessor: 'netEarningGrowthPercent',
        id: 'netEarningGrowthPercent'
      },
      {
        Header: 'Gross variance',
        accessor: 'grossEarningGrowthPercent',
        id: 'grossEarningGrowthPercent'
      }
    ],
    []
  )

  useEffect(() => {
    if (isAuditModalOpen) {
      refetchAudit()
    }
  }, [isAuditModalOpen])

  return (
    <>
      <Modal
        className='max-w-max'
        isOpen={isAuditModalOpen}
        size='full'
        onRequestClose={() => {
          handleModalClose()
        }}
      >
        <ModalHeader>
          <div>View past changes</div>
        </ModalHeader>

        <ModalBody>
          {isFetchingAudit && <MerryGoRound />}

          {!isFetchingAudit && !audit?.length && (
            <div className='type-default w-96 py-4'>
              There are no past changes
            </div>
          )}

          {!isFetchingAudit && audit?.length && (
            <AuditTable<FraudThresholdsAudit> data={audit} columns={columns} />
          )}
        </ModalBody>
      </Modal>
    </>
  )
}
