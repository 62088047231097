import * as React from 'react'

import { Pagination, useRowPagination } from '@toasttab/buffet-pui-pagination'
import {
  Body,
  Cell,
  Head,
  HeadingCell,
  Row,
  Table
} from '@toasttab/buffet-pui-table'
import { Column, useTable } from 'react-table'
import { getCurrencyString } from '../utils'

export function AuditTable<I extends Object>({
  data,
  columns
}: {
  data: I[]
  columns: Column<I>[]
}) {
  const { getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data
  })

  const { currentPageData, ...paginationProps } = useRowPagination({
    rows,
    pageSize: 10
  })

  return (
    <>
      <Table variant='plain' valign='middle'>
        <Head>
          {headerGroups.map((headerGroup) => (
            <Row
              {...headerGroup.getHeaderGroupProps()}
              key={`headingrow-${headerGroup.id}`}
            >
              {headerGroup.headers.map((column) => (
                <HeadingCell
                  {...column.getHeaderProps()}
                  key={`row-${headerGroup.id}-col-${column.id}`}
                >
                  {column.render('Header')}
                </HeadingCell>
              ))}
            </Row>
          ))}
        </Head>
        <Body {...getTableBodyProps()}>
          {currentPageData.map((row) => {
            prepareRow(row)
            return (
              <Row {...row.getRowProps()} key={`row-${row.id}`}>
                {row.cells.map((cell) => {
                  return (
                    <Cell
                      {...cell.getCellProps({
                        className: 'text-center'
                      })}
                      key={`row-${row.id}-cell-${cell.column.id}`}
                    >
                      {[
                        'netEarningAverage',
                        'grossEarningAverage',
                        'netEarningLimit',
                        'grossEarningLimit'
                      ].includes(cell.column.id)
                        ? getCurrencyString(cell.value)
                        : cell.value?.toString()}
                    </Cell>
                  )
                })}
              </Row>
            )
          })}
        </Body>
      </Table>
      <Pagination {...paginationProps} />
    </>
  )
}
