import { AccordionItem } from '@toasttab/buffet-pui-accordion/'
import { Badge, NumericBadge } from '@toasttab/buffet-pui-badge'
import { Button, ButtonGroup } from '@toasttab/buffet-pui-buttons'
import { CardContainer } from '@toasttab/buffet-pui-card'
import * as React from 'react'
import { useLocation } from 'react-router-dom'
import {
  PausedPayPeriod,
  PayPeriodRequest
} from '../models/pausedPayrollDetails'
import { getCurrencyString } from '../utils'

export function PausedPayPeriodCard({
  payPeriod,
  setRequest
}: {
  payPeriod: PausedPayPeriod
  setRequest: (request: PayPeriodRequest) => void
}) {
  const location = useLocation()
  let initialOpen = location.hash.replace('#', '') === payPeriod.uuid
  return (
    <CardContainer
      id={payPeriod.uuid}
      className='border-none py-5 px-3'
      noElevation
      noPadding
      verticalEdge='rounded'
    >
      <div className='space-y-2'>
        <div className='flex items-center px-2 py-1 space-x-2'>
          {payPeriod.hasBouncedPayroll && (
            <Badge className='rounded-full' color='warning' variant='statusSm'>
              PAST BOUNCED PAYROLLS
            </Badge>
          )}
          {expenseBadge(payPeriod, 'xs')}
        </div>

        <div className='flex justify-between px-3'>
          <div className='flex items-center font-semibold type-large space-x-2'>
            <div>
              {payPeriod.customerCode} ({payPeriod.shardName})
            </div>
          </div>
          <div>
            Posted date: {formatLocalDateTime(payPeriod.postedDateTime)}
          </div>
        </div>
        <div className='flex justify-between px-3'>
          <div>Name: {payPeriod.payPeriodName}</div>
          <div className='text-secondary'>
            Check date: {formatLocalDate(payPeriod.checkDate)}
          </div>
        </div>
      </div>
      <div className='pl-2'>
        <EmployeePaySection payPeriod={payPeriod} initialOpen={initialOpen} />
        <EmployeePayUpdatesSection
          payPeriod={payPeriod}
          initialOpen={initialOpen}
        />
        <PayrollPosterSection payPeriod={payPeriod} initialOpen={initialOpen} />
      </div>
      <ButtonGroup className='pt-6 px-3'>
        <Button
          id={`close-${payPeriod.customerCode}-${payPeriod.uuid}`}
          variant='secondary'
          onClick={() => setRequest({ payPeriod, action: 'close' })}
        >
          Manually close
        </Button>
        <Button
          id={`resume-${payPeriod.customerCode}-${payPeriod.uuid}`}
          type='submit'
          onClick={() => setRequest({ payPeriod, action: 'resume' })}
        >
          Resume sweeps
        </Button>
      </ButtonGroup>
    </CardContainer>
  )
}

const EmployeePaySection = ({
  payPeriod,
  initialOpen
}: {
  payPeriod: PausedPayPeriod
  initialOpen: boolean
}) => {
  return (
    <AccordionItem
      id={`employee-pay-${payPeriod.uuid}`}
      initialIsOpen={initialOpen}
      title={<div className='-ml-2 font-semibold'>Payroll pay information</div>}
    >
      <div className='space-y-4 -mt-3'>
        <div className='flex justify-between'>
          <div>Employee count</div>
          <div>{payPeriod.employeeCount}</div>
        </div>
        <div className='flex justify-between'>
          <div>Employee average gross</div>
          <div>
            {getCurrencyString(
              payPeriod.totalGrossPay / payPeriod.employeeCount
            )}
          </div>
        </div>
        <div className='flex justify-between'>
          <div>Employee average net</div>
          <div>
            {getCurrencyString(payPeriod.totalNetPay / payPeriod.employeeCount)}
          </div>
        </div>
        <div className='flex justify-between'>
          <div>Difference between last pay period</div>
          {expenseBadge(payPeriod)}
        </div>
      </div>
    </AccordionItem>
  )
}

const EmployeePayUpdatesSection = ({
  payPeriod,
  initialOpen
}: {
  payPeriod: PausedPayPeriod
  initialOpen: boolean
}) => {
  if (payPeriod.employeeWithBankUpdates.length === 0) {
    return (
      <div className='flex justify-between border-b py-6 pl-1 pr-3'>
        <div className='font-semibold'>Employees with new pay details</div>
        <div className='px-4 text-secondary'>0</div>
      </div>
    )
  }
  return (
    <AccordionItem
      id={`new-pay-details-${payPeriod.uuid}`}
      initialIsOpen={initialOpen}
      title={
        <div className='-ml-2 font-semibold'>
          Employees with new pay details
        </div>
      }
      secondaryAction={
        <div className='text-secondary'>
          {payPeriod.employeeWithBankUpdates.length}
        </div>
      }
    >
      <div className='space-y-4 -mt-3'>
        {payPeriod.employeeWithBankUpdates.map((employee) => (
          <div
            key={employee.uuid + payPeriod.uuid}
            className='flex justify-between'
          >
            <a
              className='underline'
              href={`/${payPeriod.customerCode}/employees/${employee.uuid}/paymentMethod`}
            >
              {employee.name}
            </a>
            <div>
              Changed IP:{' '}
              {employee.ipAddresses
                .split(',')
                .filter((ip) => ip.trim().length)
                .join(', ')}
            </div>
          </div>
        ))}
      </div>
    </AccordionItem>
  )
}

const PayrollPosterSection = ({
  payPeriod,
  initialOpen
}: {
  payPeriod: PausedPayPeriod
  initialOpen: boolean
}) => {
  const location = [payPeriod.city, payPeriod.state]
    .filter((value) => value.trim().length)
    .join(', ')
  return (
    <AccordionItem
      id={`payroll-poster-${payPeriod.uuid}`}
      initialIsOpen={initialOpen}
      title={<div className='-ml-2 font-semibold'>Payroll poster</div>}
    >
      <div className='space-y-4 -mt-3'>
        <div className='flex justify-between'>
          <div>Posted by</div>
          <div>
            {payPeriod.postedBy}({payPeriod.userEmail})
          </div>
        </div>
        <div className='flex justify-between'>
          <div>Work tax location</div>
          <div>{location.length ? location : 'N/A'}</div>
        </div>
        <div className='flex justify-between'>
          <div>Posts in the last 12 months</div>
          <div>{payPeriod.postedPayrollCount}</div>
        </div>
        <div className='flex justify-between'>
          <div>Date of last password change</div>
          <div>
            {payPeriod.lastPasswordChangedDate
              ? formatLocalDate(payPeriod.lastPasswordChangedDate)
              : 'N/A'}
          </div>
        </div>
        <div className='flex justify-between'>
          <div>IP address</div>
          <div>
            {payPeriod.userLastIp.length ? payPeriod.userLastIp : 'N/A'}
          </div>
        </div>
      </div>
    </AccordionItem>
  )
}

function expenseBadge(
  payPeriod: PausedPayPeriod,
  size: 'lg' | 'sm' | 'xs' = 'lg'
) {
  if (payPeriod.previousExpense === null) {
    return (
      <Badge className='rounded-full' color='neutral0' variant='statusSm'>
        First pay period
      </Badge>
    )
  }
  const difference = payPeriod.expense - payPeriod.previousExpense
  return (
    <NumericBadge
      size={size}
      color={difference < 0 ? 'error' : 'success'}
      icon={difference < 0 ? 'decrease' : 'increase'}
    >
      {getCurrencyString(Math.abs(difference))}
    </NumericBadge>
  )
}

const formatLocalDateTime = (dateTime: string): string => {
  const date = formatLocalDate(dateTime)
  // eslint-disable-next-line @toasttab/buffet/hard-coded-dates
  const time = new Date(dateTime).toLocaleTimeString()
  return `${time} ${date}`
}

const formatLocalDate = (dateTime: string): string => {
  return new Date(dateTime).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    timeZone: 'UTC'
  })
}
