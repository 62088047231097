import * as React from 'react'
import { ExpandIcon } from '@toasttab/buffet-pui-icons'
import { IconButton } from '@toasttab/buffet-pui-buttons'
import {
  Disclosure,
  DisclosureProps,
  useDisclosure
} from '@toasttab/buffet-pui-disclosure'
import { loadStrings, t } from './defaultStrings'

export interface AccordionItemProps extends DisclosureProps {
  title: React.ReactNode
  subtitle?: string
  unmountOnClose?: boolean
  secondaryAction?: React.ReactNode
}

export const AccordionItem = ({
  title,
  subtitle,
  unmountOnClose,
  children,
  testId = 'accordion-item',
  secondaryAction = null,
  ...props
}: React.PropsWithChildren<AccordionItemProps>) => {
  return (
    <Disclosure {...props} testId={testId} className='border-b'>
      <AccordionItemToggle
        title={title}
        subtitle={subtitle}
        secondaryAction={secondaryAction}
      />
      <Disclosure.Content unmountOnClose={unmountOnClose} className='p-3 pb-6'>
        {children}
      </Disclosure.Content>
    </Disclosure>
  )
}

interface AccordionItemToggleProps {
  title: React.ReactNode
  subtitle?: string
  secondaryAction?: React.ReactNode
}

const AccordionItemToggle = ({
  title,
  subtitle,
  secondaryAction
}: AccordionItemToggleProps) => {
  const { isOpen, toggleProps } = useDisclosure()
  loadStrings()

  return (
    <header
      data-testid={`${toggleProps['data-testid']}-outer`}
      className='gap-3 w-full flex justify-between items-center py-4 px-3 focus-visible:rounded-md hover:bg-darken-4 border-none outline-none focus-visible:shadow-focus cursor-pointer'
      onClick={toggleProps.onClick}
    >
      <hgroup id={`${toggleProps.id}-title`} className='text-left flex-grow'>
        <h4 className='type-default text-default font-bold'>{title}</h4>
        {subtitle && (
          <h5 className='type-subhead text-secondary'>{subtitle}</h5>
        )}
      </hgroup>
      {secondaryAction}
      <IconButton
        {...toggleProps}
        icon={
          <ExpandIcon
            size='sm'
            className='text-secondary'
            isExpanded={isOpen}
            aria-label={isOpen ? t('close') : t('open')}
          />
        }
      />
    </header>
  )
}
