import { QueryClientProvider } from '@tanstack/react-query'
import * as React from 'react'
import { BrowserRouter, useRoutes } from 'react-router-dom'

import { queryClient } from '@local/api/client'
import { useUser } from '@toasttab/ec-session'

import { SnackBarProvider } from '@toasttab/buffet-pui-snackbars'
import { EntryRoute } from './routes'

//tell the router that it's ok to load anywhere but not do anything
const wildcard = { path: '*', element: null }

//this is the whole app. the routes do 99% of the work
const App = () => {
  const { isUserSuperAdmin, isUserSuperAdminLight } = useUser()
  const routes = useRoutes([EntryRoute, wildcard])

  if (isUserSuperAdmin || isUserSuperAdminLight) {
    return routes
  }

  return <>NOT AUTHORIZED</>
}

/**
 * This is the full application
 */
const AppBootstrap = () => (
  <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <SnackBarProvider>
        <App />
      </SnackBarProvider>
    </BrowserRouter>
  </QueryClientProvider>
)

export { App, AppBootstrap }
