import {
  LayoutProvider,
  Page,
  PageBody,
  Title
} from '@toasttab/buffet-pui-config-templates'
import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs
} from '@toasttab/buffet-pui-tabs'
import { useUser } from '@toasttab/ec-session'
import * as React from 'react'
import { useState } from 'react'
import { Outlet, RouteObject, useSearchParams } from 'react-router-dom'
import { VoidRequestTable } from './VoidRequestTable'
import { VoidRequestStatus } from './models/voidRequestModel'

export const VoidRequestsRoute: RouteObject = {
  path: 'payroll-ops/void-requests',
  element: <VoidRequests />,
  children: []
}

function VoidRequests() {
  const [selectedState, setSelectedState] = useState<VoidRequestStatus>(
    VoidRequestStatus.OPEN
  )

  const [searchParams] = useSearchParams()

  const showAll = searchParams.has('showAll')
    ? searchParams.get('showAll') === 'true'
    : false

  const { isUserSuperAdmin } = useUser()

  if (showAll && !isUserSuperAdmin) {
    return (
      <div className='type-default'>hmm... you aren't suppose to be here</div>
    )
  }

  return (
    <LayoutProvider>
      <Page className='mt-10'>
        <PageBody className='type-default'>
          <Title>Void Requests</Title>
          <Tabs>
            <TabList className='mx-4 md:mx-0'>
              <Tab>Open</Tab>
              <Tab>Closed</Tab>
              <Tab>Expired</Tab>
            </TabList>
            <TabPanels className='p-4 type-default md:p-6 md:px-0'>
              <TabPanel
                onSelection={() => setSelectedState(VoidRequestStatus.OPEN)}
              >
                <VoidRequestTable
                  status={VoidRequestStatus.OPEN}
                  selected={selectedState === VoidRequestStatus.OPEN}
                />
              </TabPanel>
              <TabPanel
                onSelection={() => setSelectedState(VoidRequestStatus.CLOSED)}
              >
                <VoidRequestTable
                  status={VoidRequestStatus.CLOSED}
                  selected={selectedState === VoidRequestStatus.CLOSED}
                />
              </TabPanel>
              <TabPanel
                onSelection={() => setSelectedState(VoidRequestStatus.EXPIRED)}
              >
                <VoidRequestTable
                  status={VoidRequestStatus.EXPIRED}
                  selected={selectedState === VoidRequestStatus.EXPIRED}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
          <Outlet />
        </PageBody>
      </Page>
    </LayoutProvider>
  )
}

export default VoidRequests
