import { Button } from '@toasttab/buffet-pui-buttons'
import { NumberInputField } from '@toasttab/buffet-pui-forms'
import { DeleteIcon } from '@toasttab/buffet-pui-icons'
import { Modal, ModalHeader } from '@toasttab/buffet-pui-modal'
import { useCompanyCode, useUser } from '@toasttab/ec-session'
import { Formik } from 'formik'
import * as React from 'react'
import { useEffect, useState } from 'react'
import * as Yup from 'yup'
import {
  CustomerFraudThresholdsInput,
  CustomerFraudThresholdsRequest
} from '../models/FraudThresholds'
import {
  useCustomerThresholdDeletion,
  useCustomerThresholds
} from '../queries/FraudQueries'
import { ConfirmationModal } from './ConfirmationModal'

export function CustomerThresholdsModal({
  isModalOpen,
  handleModalClose,
  customerUuid
}: {
  handleModalClose: (request: CustomerFraudThresholdsRequest | null) => void
  isModalOpen: boolean
  customerUuid: string
}) {
  const { isUserSuperAdmin } = useUser()
  const companyCode = useCompanyCode()

  const { data, refetch } = useCustomerThresholds(customerUuid)
  const { mutate: deleteCustomerThreshold } = useCustomerThresholdDeletion(
    () => {
      setDeleting(false)
      refetch()
    }
  )

  const [isEditing, setEditing] = useState<boolean>(false)
  const [isDeleting, setDeleting] = useState<boolean>(false)

  useEffect(() => {
    if (isModalOpen) {
      refetch()
    }
  }, [isModalOpen])

  const canEdit = isUserSuperAdmin && isEditing

  let initialValue = {
    netEarningAverage: data?.netEarningAverage,
    grossEarningAverage: data?.grossEarningAverage,
    netEarningGrowthPercent: data?.netEarningGrowthPercent,
    grossEarningGrowthPercent: data?.grossEarningGrowthPercent,
    pastPayrollCount: data?.pastPayrollCount
  }

  const requestSchema = Yup.object({
    netEarningAverage: Yup.number().required('value is required').min(0),
    grossEarningAverage: Yup.number().required('value is required').min(0),
    netEarningGrowthPercent: Yup.number().required('value is required').min(0),
    grossEarningGrowthPercent: Yup.number()
      .required('value is required')
      .min(0),
    pastPayrollCount: Yup.number().required('value is required').min(0)
  }).defined()

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        size='xxl'
        onRequestClose={() => {
          setEditing(false)
          handleModalClose(null)
        }}
      >
        <ModalHeader>
          <div>{isEditing ? 'Change' : 'View'} alert parameters</div>
        </ModalHeader>

        <Formik<CustomerFraudThresholdsInput>
          validationSchema={isEditing ? requestSchema : null}
          enableReinitialize={true}
          initialValues={initialValue}
          onSubmit={(request) => {
            setEditing(false)
            handleModalClose({
              customerUuid,
              netEarningAverage: request.netEarningAverage!,
              grossEarningAverage: request.grossEarningAverage!,
              netEarningGrowthPercent: request.netEarningGrowthPercent!,
              grossEarningGrowthPercent: request.grossEarningGrowthPercent!,
              pastPayrollCount: request.pastPayrollCount!
            })
          }}
        >
          {(props) => (
            <form onSubmit={props.handleSubmit}>
              <Modal.Body>
                <div className='space-y-3'>
                  <div className='type-default'>
                    These parameters will only apply to <b>{companyCode}</b>, to
                    view all parameters go{' '}
                    <a href='/payroll-ops/fraud-dashboard'>here</a>.
                  </div>
                  <div className='bg-gray-0 p-6'>
                    <div className='pr-4'>
                      <div className='text-default font-semibold'>
                        Payroll comparison for fraud alerts
                      </div>
                      <div className='type-subhead'>
                        The rules below will compare the current payroll to the
                        entered number of past payroll
                      </div>
                    </div>
                    <div className='pt-2'>
                      <NumberInputField
                        label='Number of payrolls to compare'
                        name='pastPayrollCount'
                        decimalScale={0}
                        prefixVariant='bgGray'
                        readOnly={!canEdit}
                      />
                    </div>
                  </div>
                  <div className='type-subhead text-secondary font-semibold pt-2'>
                    New payroll customer
                  </div>
                  <div className='grid md:grid-cols-2 w-full gap-4'>
                    <NumberInputField
                      label='Net greater than'
                      name='netEarningAverage'
                      decimalScale={2}
                      prefixVariant='bgGray'
                      prefix='$'
                      readOnly={!canEdit}
                    />
                    <NumberInputField
                      label='Gross greater than'
                      name='grossEarningAverage'
                      decimalScale={2}
                      prefixVariant='bgGray'
                      prefix='$'
                      readOnly={!canEdit}
                    />
                  </div>
                  <div className='type-subhead text-secondary font-semibold pt-2'>
                    Existing payroll employee paid amount
                  </div>
                  <div className='grid md:grid-cols-2 w-full gap-4'>
                    <NumberInputField
                      label='Variance from average net amounts'
                      name='netEarningGrowthPercent'
                      inputMode='numeric'
                      decimalScale={0}
                      suffixVariant='bgGray'
                      suffix='%'
                      readOnly={!canEdit}
                    />
                    <NumberInputField
                      label='Variance from average gross amounts'
                      name='grossEarningGrowthPercent'
                      inputMode='numeric'
                      decimalScale={0}
                      suffixVariant='bgGray'
                      suffix='%'
                      readOnly={!canEdit}
                    />
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant='link'
                  disabled={data === null}
                  iconRight={<DeleteIcon aria-label='remove override' />}
                  className='flex-grow sm:flex-none'
                  onClick={() => {
                    handleModalClose(null)
                    setDeleting(true)
                  }}
                >
                  Remove override
                </Button>
                {canEdit && (
                  <Button
                    type='submit'
                    className='flex-grow sm:flex-none'
                    disabled={!props.isValid || !props.dirty}
                  >
                    Save
                  </Button>
                )}
                {isUserSuperAdmin && !isEditing && (
                  <Button
                    variant='secondary'
                    className='flex-grow sm:flex-none'
                    onClick={() => setEditing(true)}
                  >
                    Edit parameters
                  </Button>
                )}
              </Modal.Footer>
            </form>
          )}
        </Formik>
      </Modal>
      <ConfirmationModal
        header='Are you sure you want to remove override?'
        saveText='Yes, remove override'
        handleModalClose={() => {
          setDeleting(false)
        }}
        isModalOpen={isDeleting}
        mutate={() => deleteCustomerThreshold()}
        body={
          <div>
            This override will no longer be active and will use the Enterprise
            fraud dashboard alert parameters to detect fraud.
          </div>
        }
      />
    </>
  )
}
