import * as React from 'react'
import { Link, Outlet, RouteObject, useLocation } from 'react-router-dom'
import { AuditRoute } from './Audit'
import { RTSRoute } from './rts/Rts'

import {
  HeadingGroup,
  Page,
  PageBody,
  PageHeader,
  Title
} from '@toasttab/buffet-pui-config-templates'
import {
  SecondaryNav,
  SecondaryNavItem,
  SecondaryNavSection
} from '@toasttab/buffet-pui-navigation'

export const EnterpriseReportsRoute: RouteObject = {
  path: 'payroll-ops/reports',
  element: <EnterpriseReports />,
  children: [AuditRoute, RTSRoute]
}

const PATHS = {
  rts: '/payroll-ops/reports/rts',
  audit: '/payroll-ops/reports/audit'
}

function EnterpriseReports() {
  const location = useLocation()

  return (
    <>
      <Page>
        <PageHeader>
          <HeadingGroup>
            <Title>Payroll operations internal reports</Title>
          </HeadingGroup>
        </PageHeader>
        <PageBody>
          <div className='flex stretch-items'>
            <div
              className='pr-6'
              style={{
                minWidth: '200px',
                maxWidth: '300px'
              }}
            >
              <SecondaryNav>
                <SecondaryNavSection>
                  <SecondaryNavItem
                    title='RTS file'
                    value='/rts'
                    as={Link}
                    to={PATHS.rts}
                    initialActive={location.pathname.endsWith(PATHS.rts)}
                  />
                  <SecondaryNavItem
                    title='Quarterly audit'
                    value='/audit'
                    as={Link}
                    to={PATHS.audit}
                    initialActive={location.pathname.endsWith(PATHS.audit)}
                  />
                </SecondaryNavSection>
              </SecondaryNav>
            </div>
            <Outlet />
          </div>
        </PageBody>
      </Page>
    </>
  )
}
