export function getCurrencyString(value: number | null | undefined) {
  if (value === null || value === undefined) {
    return ''
  }
  return value.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2
  })
}
