import * as React from 'react'

import { useQuery } from '@tanstack/react-query'
import { Select } from '@toasttab/buffet-pui-select'
import {
  IdentifiedObject,
  VoidFilterType,
  VoidRequestStatus
} from './models/voidRequestModel'

export function VoidRequestFilterSelection({
  filterType,
  parentValue,
  currentValue,
  showAll,
  status,
  disabled,
  setFilterValue
}: {
  filterType: VoidFilterType
  parentValue?: string
  currentValue: string | undefined
  showAll: boolean
  status: VoidRequestStatus
  disabled: boolean
  setFilterValue: (value: string) => void
}) {
  const isCompanyFilter = filterType === VoidFilterType.CUSTOMER
  const filterValue = isCompanyFilter ? '' : `&customer=${parentValue}`

  const fetchTargetPayPeriods = async () => {
    const res = await fetch(
      `/void-requests/filters?status=${status}&showAll=${showAll}${filterValue}`
    )
    if (!res.ok) {
      throw new Error('HTTP error: ' + res.status)
    }
    return res.json()
  }

  // should display 4 prior quarters + current quarter and 3 next quarters
  const getQuarters = (): { label: string; value: string | undefined }[] => {
    const today = new Date()
    const currentYear = today.getFullYear()
    const currentQuarter = Math.floor(today.getMonth() / 3)
    let quarters: { label: string; value: string | undefined }[] = [
      {
        label: `All ${filterType.toLowerCase()}`,
        value: undefined
      }
    ]
    for (let i = -4 + currentQuarter; i <= 3 + currentQuarter; i++) {
      let year = currentYear
      let quarter = i
      if (i <= 0) {
        year = currentYear - 1
        quarter = 4 + i
      } else if (i >= 5) {
        year = currentYear + 1
        quarter = i - 4
      }
      quarters.push({
        label: `${year} Q${quarter}`,
        value: `${year},${quarter}`
      })
    }

    return quarters
  }

  const {
    data
  }: {
    data: undefined | null | IdentifiedObject[]
    isFetching: boolean
  } = useQuery({
    queryKey: [parentValue, filterType],
    queryFn: () => {
      if (
        filterType === VoidFilterType.CUSTOMER ||
        (filterType === VoidFilterType.PAYPERIOD && parentValue)
      ) {
        return fetchTargetPayPeriods()
      } else {
        return Promise.resolve([])
      }
    }
  })

  const filterOptions =
    filterType === VoidFilterType.QUARTER
      ? getQuarters()
      : data?.length
      ? [
          {
            label: `All ${filterType.toLowerCase()}`,
            value: undefined
          },
          ...data.map((d) => ({
            label: d.name,
            value: d.uuid
          }))
        ]
      : []

  return (
    <Select
      enableSearch={filterType === VoidFilterType.CUSTOMER}
      searchPlaceholder='Search customers'
      label={filterType}
      name={filterType}
      value={currentValue}
      disabled={disabled || filterOptions.length === 0}
      options={
        filterType === VoidFilterType.QUARTER ? getQuarters() : filterOptions
      }
      onChange={setFilterValue}
    />
  )
}
