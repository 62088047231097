import { Button } from '@toasttab/buffet-pui-buttons'
import { CardContainer } from '@toasttab/buffet-pui-card'
import { DividingLine } from '@toasttab/buffet-pui-config-templates'
import {
  NumberInputField,
  ToggleSwitchWithTextField
} from '@toasttab/buffet-pui-forms'
import { Modal, ModalHeader } from '@toasttab/buffet-pui-modal'
import { InfoTooltip } from '@toasttab/buffet-pui-tooltip'
import { useUser } from '@toasttab/ec-session'
import { Formik } from 'formik'
import * as React from 'react'
import { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { FraudThresholds } from '../models/FraudThresholds'
import {
  useThresholdModeMutation,
  useThresholds
} from '../queries/FraudQueries'

export function FraudThresholdsModal({
  isModalOpen,
  handleModalClose
}: {
  handleModalClose: (request: FraudThresholds | null) => void
  isModalOpen: boolean
}) {
  const { isUserSuperAdmin } = useUser()

  const { data, refetch } = useThresholds()

  const { mutate: updateFraudCheckMode } = useThresholdModeMutation()

  const [isEditing, setEditing] = useState<boolean>(false)

  useEffect(() => {
    if (isModalOpen) {
      refetch()
    }
  }, [isModalOpen])

  const canEditField = isUserSuperAdmin && isEditing

  let initialValue = data ?? {
    newCustomerPayrollCount: 0,
    newCustomerInactiveDay: 0,
    newCustomerNetEarningAverage: 0,
    newCustomerGrossEarningAverage: 0,
    newEmployeeNetEarningLimit: 0,
    newEmployeeGrossEarningLimit: 0,
    netEarningGrowthPercent: 0,
    grossEarningGrowthPercent: 0,
    netEarningMinimum: 0,
    grossEarningMinimum: 0,
    isRuleBasedCheckEnabled: false
  }

  const requestSchema = Yup.object({
    newCustomerPayrollCount: Yup.number().required('value is required').min(0),
    newCustomerInactiveDay: Yup.number().required('value is required').min(0),
    newCustomerNetEarningAverage: Yup.number()
      .required('value is required')
      .min(0),
    newCustomerGrossEarningAverage: Yup.number()
      .required('value is required')
      .min(0),
    newEmployeeNetEarningLimit: Yup.number()
      .required('value is required')
      .min(0),
    newEmployeeGrossEarningLimit: Yup.number()
      .required('value is required')
      .min(0),
    netEarningGrowthPercent: Yup.number().required('value is required').min(0),
    grossEarningGrowthPercent: Yup.number()
      .required('value is required')
      .min(0),
    netEarningMinimum: Yup.number().required('value is required').min(0),
    grossEarningMinimum: Yup.number().required('value is required').min(0)
  }).defined()

  return (
    <>
      <Modal
        className='overflow-y-auto'
        isOpen={isModalOpen}
        size='xxl'
        onRequestClose={() => {
          setEditing(false)
          handleModalClose(null)
        }}
      >
        <ModalHeader>
          <div>{isEditing ? 'Change' : 'View'} alert parameters</div>
        </ModalHeader>

        <Formik<FraudThresholds>
          validationSchema={requestSchema}
          enableReinitialize={true}
          initialValues={initialValue}
          onSubmit={(request) => {
            setEditing(false)
            handleModalClose(request)
          }}
        >
          {(props) => (
            <form onSubmit={props.handleSubmit}>
              <Modal.Body>
                <div className='space-y-3'>
                  <div className='type-default'>
                    These parameters will apply to all company codes unless
                    overridden{' '}
                    <a href='/payroll-ops/fraud-dashboard?isCustomer=true'>
                      on a company level
                    </a>
                    .
                  </div>
                  <div className='py-4'>
                    <CardContainer
                      className='flex p-4'
                      noPadding
                      verticalEdge='rounded'
                    >
                      <div className='pr-4'>
                        <div className='text-default font-semibold pb-2'>
                          Turn on Rule-Based parameter alerts
                        </div>
                        <div>
                          When turned on, parameter alerts will be based on
                          inputs below. If turned off parameter alerts will be
                          set to threshold-based.
                        </div>
                      </div>
                      <div>
                        <ToggleSwitchWithTextField
                          name='isRuleBasedCheckEnabled'
                          disabled={data === null || !isUserSuperAdmin}
                          onChange={() => {
                            updateFraudCheckMode(
                              !props.values.isRuleBasedCheckEnabled
                            )
                          }}
                          aria-label='enable-rule-based-Check'
                        />
                      </div>
                    </CardContainer>
                  </div>
                  <div className='bg-gray-0 p-6'>
                    <div className='text-default font-semibold '>
                      What is considered a new customer?
                    </div>
                    <div className='type-subhead'>
                      Anything more than both of these values is considered an{' '}
                      <b>existing</b> customer.
                    </div>
                    <div className='grid md:grid-cols-2 w-full gap-4 pt-4 items-end'>
                      <NumberInputField
                        label='Amount of payrolls'
                        name='newCustomerPayrollCount'
                        decimalScale={0}
                        readOnly={!canEditField}
                      />
                      <NumberInputField
                        label={<div className='-mb-1'>Amount of days</div>}
                        name='newCustomerInactiveDay'
                        helperIconButton={
                          <InfoTooltip cropToIcon>
                            Days since last payroll posted
                          </InfoTooltip>
                        }
                        decimalScale={0}
                        readOnly={!canEditField}
                      />
                    </div>
                  </div>
                  <div className='my-4'>
                    <div className='type-large font-semibold text-default py-4'>
                      New customer
                    </div>
                    <div className='grid md:grid-cols-2 w-full gap-4'>
                      <NumberInputField
                        label='Net greater than'
                        name='newCustomerNetEarningAverage'
                        decimalScale={2}
                        prefixVariant='bgGray'
                        prefix='$'
                        readOnly={!canEditField}
                      />
                      <NumberInputField
                        label='Gross greater than'
                        name='newCustomerGrossEarningAverage'
                        decimalScale={2}
                        prefixVariant='bgGray'
                        prefix='$'
                        readOnly={!canEditField}
                      />
                    </div>
                    <DividingLine />
                  </div>
                  <div className='type-large font-semibold text-default'>
                    Existing customers
                  </div>
                  <div className='type-subhead text-secondary font-semibold'>
                    <div className='flex items-center'>
                      <div className='pr-1'>New payroll employees</div>
                      <InfoTooltip cropToIcon>
                        First-time employee payments
                      </InfoTooltip>
                    </div>
                  </div>
                  <div className='grid md:grid-cols-2 w-full gap-4'>
                    <NumberInputField
                      label='Net amount greater than'
                      name='newEmployeeNetEarningLimit'
                      decimalScale={2}
                      prefixVariant='bgGray'
                      prefix='$'
                      readOnly={!canEditField}
                    />
                    <NumberInputField
                      label='Gross amount greater than'
                      name='newEmployeeGrossEarningLimit'
                      decimalScale={2}
                      prefixVariant='bgGray'
                      prefix='$'
                      readOnly={!canEditField}
                    />
                  </div>
                  <div className='type-subhead text-secondary font-semibold pt-2'>
                    Existing payroll employees
                  </div>
                  <div className='grid md:grid-cols-2 w-full gap-4'>
                    <NumberInputField
                      label='Net amount minumum'
                      name='netEarningMinimum'
                      decimalScale={2}
                      prefixVariant='bgGray'
                      prefix='$'
                      readOnly={!canEditField}
                    />
                    <NumberInputField
                      label='Gross amount minumum'
                      name='grossEarningMinimum'
                      decimalScale={2}
                      prefixVariant='bgGray'
                      prefix='$'
                      readOnly={!canEditField}
                    />
                  </div>
                  <div className='grid md:grid-cols-2 w-full gap-4'>
                    <NumberInputField
                      label='Variance from average net amounts'
                      name='netEarningGrowthPercent'
                      inputMode='numeric'
                      decimalScale={0}
                      suffixVariant='bgGray'
                      suffix='%'
                      readOnly={!canEditField}
                    />
                    <NumberInputField
                      label='Variance from average gross amounts'
                      name='grossEarningGrowthPercent'
                      inputMode='numeric'
                      decimalScale={0}
                      suffixVariant='bgGray'
                      suffix='%'
                      readOnly={!canEditField}
                    />
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                {canEditField && (
                  <Button
                    type='submit'
                    className='flex-grow sm:flex-none'
                    disabled={!props.isValid || !props.dirty}
                  >
                    Save
                  </Button>
                )}
                {!isEditing && isUserSuperAdmin && (
                  <Button
                    variant='secondary'
                    className='flex-grow sm:flex-none'
                    onClick={() => setEditing(true)}
                  >
                    Edit parameters
                  </Button>
                )}
              </Modal.Footer>
            </form>
          )}
        </Formik>
      </Modal>
    </>
  )
}
