import * as React from 'react'
import cx from 'classnames'
import { IconWrapper, IconProps, sizes } from '../../IconWrapper'
export const ArrowIncreaseIcon = React.forwardRef(function ArrowIncreaseIcon(
  props: IconProps,
  ref?: React.ForwardedRef<HTMLElement>
) {
  return (
    <IconWrapper ref={ref} {...props}>
      <>
        {
          <svg
            width={24}
            height={24}
            viewBox='0 0 24 24'
            fill='none'
            className={cx(
              'inline-block align-middle',
              sizes[props.size || 'sm'].className
            )}
          >
            <path
              d='M7.473 16.5h8.957c1.642 0 2.09-1.2.895-2.8l-4.18-5.6c-.447-.6-.746-.6-1.194-.6-.448 0-.746.2-1.045.6l-4.18 5.6c-1.194 1.6-.895 2.8.747 2.8z'
              fill='currentColor'
            />
          </svg>
        }
      </>
    </IconWrapper>
  )
})
