import * as React from 'react'
import {
  ToggleSwitch,
  ToggleSwitchProps
} from '@toasttab/buffet-pui-toggle-switches'
import { RemoveFields } from '@toasttab/buffet-shared-types'
import { useFieldFast } from '../useFieldFast'
import {
  FormValuesWithName,
  PrimitiveToggleValue,
  SharedToggleSwitchFieldProps
} from '../commonTypes'

export type ToggleSwitchFieldProps<
  FormValues extends FormValuesWithName = string,
  TAffirmative extends PrimitiveToggleValue = PrimitiveToggleValue,
  TNegative extends PrimitiveToggleValue = TAffirmative
> = SharedToggleSwitchFieldProps<FormValues, TAffirmative, TNegative> &
  RemoveFields<ToggleSwitchProps, 'name'>

export const ToggleSwitchField = <
  FormValues extends FormValuesWithName = string
>({
  onChange,
  activeValue = true,
  inactiveValue = false,
  ...props
}: ToggleSwitchFieldProps<FormValues>) => {
  const [{ value }, , helpers] = useFieldFast({ ...props, size: undefined })
  return (
    <ToggleSwitch
      {...props}
      isActive={value === activeValue}
      onChange={(e) => {
        onChange?.(e)
        helpers.setValue(value === activeValue ? inactiveValue : activeValue)
      }}
    />
  )
}
